import axios from "axios";

import byd from "../assets/manufacturers/byd.png";
import clemap from "../assets/manufacturers/clemap.png";
import daikin from "../assets/manufacturers/daikin.png";
import delta from "../assets/manufacturers/delta.png";
import fronius from "../assets/manufacturers/fronius.png";
import goodwee from "../assets/manufacturers/goodwee.png";
import enphase from "../assets/manufacturers/enphase.png";
import huawei from "../assets/manufacturers/huawei.png";
import sma from "../assets/manufacturers/sma.png";
import solis from "../assets/manufacturers/solis.png";
import sungrow from "../assets/manufacturers/sungrow.png";
import jasolar from "../assets/manufacturers/jasolar.png";
import jinko from "../assets/manufacturers/jinko.png";
import varta from "../assets/manufacturers/varta.png";
import solaredge from "../assets/manufacturers/solaredge.png";
import longi from "../assets/manufacturers/longi.png";
import sunsynk from "../assets/manufacturers/sunsynk.png";

const manufacturerLogos = {
	byd: byd,
	clemap: clemap,
	daikin: daikin,
	delta: delta,
	fronius: fronius,
	goodwe: goodwee,
	enphase: enphase,
	huawei: huawei,
	sma: sma,
	solis: solis,
	sungrow: sungrow,
	jasolar: jasolar,
	jinko: jinko,
	varta: varta,
	solaredge: solaredge,
	longi: longi,
	sunsynk: sunsynk,
};

export function getBrightness(color) {
	const rgb = parseInt(color.replace("#", ""), 16); // convert rrggbb to decimal
	const r = (rgb >> 16) & 0xff; // extract red
	const g = (rgb >> 8) & 0xff; // extract green
	const b = (rgb >> 0) & 0xff; // extract blue

	// Calculate brightness according to the formula: (299*R + 587*G + 114*B) / 1000
	return (299 * r + 587 * g + 114 * b) / 1000;
}

// Function to convert audio blob to base64 encoded string
export function audioBlobToBase64(blob) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onloadend = () => {
			const arrayBuffer = reader.result;
			const base64Audio = btoa(
				new Uint8Array(arrayBuffer).reduce(
					(data, byte) => data + String.fromCharCode(byte),
					""
				)
			);
			resolve(base64Audio);
		};
		reader.onerror = reject;
		reader.readAsArrayBuffer(blob);
	});
}

// Helper function to replace underscores with spaces in labels
export function formatDeviceName(name) {
	return name.replace(/_/g, " ");
}

function unifyName(str) {
	return str.split(" ").join("");
}
export function manufacturerLogo(manufacturer) {
	return manufacturerLogos[unifyName(manufacturer).toLowerCase()];
}

export const sendQuestionData = async (device, question, answer) => {
	const instanceId = process.env.REACT_APP_INSTANCE_ID;
	const environment = process.env.REACT_APP_ENV;
	let logUrl = null;

	if (environment === "staging") {
		logUrl =
			"https://admin-api.service-assistant-staging.com/instance/record-question";
	} else if (environment === "production") {
		logUrl = "https://admin-api.service-assistant.ai/instance/record-question";
	}

	if (logUrl) {
		try {
			const res = await axios.post(
				logUrl,
				{
					instanceId: instanceId,
					deviceName: device,
					question: question,
					answer: answer,
				},
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			);
			return res.data.recordId;
		} catch (err) {
			console.error("Failed to log question data:", err.message);
			return null;
		}
	}
	return null;
};

export const sendUserFeedback = async (recordId, positive) => {
	const environment = process.env.REACT_APP_ENV;
	let logUrl = null;

	if (environment === "staging") {
		logUrl =
			"https://admin-api.service-assistant-staging.com/instance/mark-record";
	}
	if (environment === "production") {
		logUrl = "https://admin-api.service-assistant.ai/instance/mark-record";
	}

	if (logUrl) {
		try {
			await axios.post(
				logUrl,
				{
					recordId: recordId,
					positive: positive,
				},
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			);
		} catch (err) {
			console.error("Failed to log user feedback:", err.message);
		}
	}
};
