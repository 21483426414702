/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";
import Select from "react-select";
import { formatDeviceName } from "../utils";
import useUIText from "../hooks/translation";

const AutoSelectManufacturerAdvanced = ({
	items,
	userInput,
	setUserInput,
	setExampleQuestions,
	showAsterisk = false,
	reset,
	setReset,
	setRelatedDevices,
}) => {
	const uiText = useUIText();
	const [devices, setDevices] = useState([]);
	const [selectedType, setSelectedType] = useState(null);
	const [selectedDevice, setSelectedDevice] = useState(null);

	const types = useMemo(() => {
		return items?.categories.map((category) => ({
			value: category.name,
			label:
				uiText?.deviceTypes?.[category.name?.toLowerCase()] || category.name,
		}));
	}, [items, uiText]);

	useEffect(() => {
		if (selectedType) {
			const selectedCategory = items?.categories.find(
				(category) => category.name === selectedType.value
			);
			const devices =
				selectedCategory?.devices?.map((item) => ({
					value: item.deviceName,
					label: formatDeviceName(item.deviceName),
				})) || [];
			setDevices(devices);
			setSelectedDevice(null); // Reset selected device when type changes
			setUserInput({ ...userInput, device: "" });
			setExampleQuestions([]);
		} else {
			setDevices([]);
			setSelectedDevice(null);
			setExampleQuestions([]);
		}
	}, [items, selectedType]);

	useEffect(() => {
		if (selectedType) {
			setSelectedType({
				value: selectedType.value,
				label:
					uiText?.deviceTypes?.[selectedType.value?.toLowerCase()] ||
					selectedType.value,
			});
		}
	}, [uiText]);

	useEffect(() => {
		if (reset) {
			setSelectedType(null);
			setSelectedDevice(null);
			setExampleQuestions([]);
			setReset(false);
		}
	}, [reset]);

	useEffect(() => {
		// Assuming userInput.device contains the device name
		const newDeviceName = userInput.device;

		// Find the device object that matches the new device name
		const matchingDevice = devices.find(
			(device) => device.value === newDeviceName
		);

		// Update selectedDevice with the found device object or null if not found
		setSelectedDevice(matchingDevice || null);
	}, [userInput.device, devices]);
	
	return (
		<>
			<label htmlFor="type">
				{items?.label || uiText?.form?.deviceType}{" "}
				{showAsterisk && <span>*</span>}
			</label>
			<Select
				className="basic-single"
				classNamePrefix="select"
				isClearable
				isSearchable
				options={types}
				placeholder={uiText?.form?.deviceTypePlaceholder}
				value={selectedType}
				onChange={(value) => {
					setSelectedType(value);
					setUserInput({ ...userInput, device: "" });
				}}
			/>
			<label htmlFor="device">
				{uiText?.form?.deviceModel} {showAsterisk && <span>*</span>}
			</label>
			<Select
				className="basic-single"
				classNamePrefix="select"
				isClearable
				isSearchable
				options={devices}
				placeholder={
					devices.length === 0
						? uiText?.form?.manufacturerPlaceholder1
						: uiText?.form?.deviceModelPlaceholder2
				}
				value={selectedDevice}
				onChange={(value) => {
					setSelectedDevice(value);
					const selectedCategory = items.categories.find(
						(category) => category.name === selectedType.value
					);
					const selectedDevice = selectedCategory.devices.find(
						(device) => device.deviceName === value.value
					);
					setUserInput({ ...userInput, device: value?.value || "" });
					setExampleQuestions(selectedDevice.exampleQuestions);
					setRelatedDevices(selectedDevice.relatedDevices || []);
				}}
				isDisabled={devices.length === 0}
			/>
		</>
	);
};

export default AutoSelectManufacturerAdvanced;
