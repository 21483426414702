import { useState, useEffect, useContext } from "react";
import axios from "axios";
import Select from "react-dropdown-select";
import {
	handleSendResult,
	handleSendRelatedDeviceClick,
	handleFeedbackSubmit,
} from "../zaps/zaps";
import { getBrightness, audioBlobToBase64, formatDeviceName } from "../utils";
import StepsComponent from "./StepsComponent";
import NoResults from "./NoResults";
import ProgressBar from "./ProgressBar";
import VoiceAnimation from "./VoiceAnimation";
import Warning from "./Warning";
import AutoSelect from "./AutoSelect";
import AutoSelectDistributors from "./AutoSelectDistributors";
import DownloadIcon from "../assets/download";
import ArrowIcon from "../assets/arrow";
import { ReactComponent as CloseIcon } from "../assets/close.svg";
import ThumbUpIcon from "../assets/thumbUp";
import ThumbDownIcon from "../assets/thumbDown";
import MicrophoneIcon from "../assets/mic";
import ResultsIcon from "../assets/results";
import Alert from "./Alert";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Accordion from "./Accordion";
import { AppContext } from "../context";
import useUIText from "../hooks/translation";
import AutoSelectManufacturerAdvanced from "./AutoSelectManufacturerAdvanced";
import { sendQuestionData, sendUserFeedback } from "../utils";

const languageCodeMap = {
	English: "en-US",
	German: "de-DE",
	French: "fr-FR",
	Polish: "pl-PL",
	Spanish: "es-ES",
};

const FormContainer = ({
	variables,
	setFeedbackIsAdded,
	resetForm,
	setResetForm,
}) => {
	const uiText = useUIText();

	const { instanceInfo, changeInstanceLanguage } = useContext(AppContext);
	const mainLanguage = instanceInfo.mainLanguage;

	const environment = process.env.REACT_APP_ENV;

	let options = [
		{ value: "English", label: "English" },
		{ value: "Arabic", label: "Arabic" },
		{ value: "Bulgarian", label: "Bulgarian" },
		{ value: "Chinese", label: "Chinese" },
		{ value: "Czech", label: "Czech" },
		{ value: "Danish", label: "Danish" },
		{ value: "Dutch", label: "Dutch" },
		{ value: "Estonian", label: "Estonian" },
		{ value: "Finnish", label: "Finnish" },
		{ value: "French", label: "French" },
		{ value: "German", label: "German" },
		{ value: "Greek", label: "Greek" },
		{ value: "Hungarian", label: "Hungarian" },
		{ value: "Indonesian", label: "Indonesian" },
		{ value: "Italian", label: "Italian" },
		{ value: "Japanese", label: "Japanese" },
		{ value: "Korean", label: "Korean" },
		{ value: "Latvian", label: "Latvian" },
		{ value: "Lithuanian", label: "Lithuanian" },
		{ value: "Norwegian", label: "Norwegian" },
		{ value: "Polish", label: "Polish" },
		{ value: "Portuguese", label: "Portuguese" },
		{ value: "portuguese-brazilian", label: "Portuguese (Brazilian)" },
		{ value: "Romanian", label: "Romanian" },
		{ value: "Russian", label: "Russian" },
		{ value: "Slovak", label: "Slovak" },
		{ value: "Slovenian", label: "Slovenian" },
		{ value: "Spanish", label: "Spanish" },
		{ value: "Swedish", label: "Swedish" },
		{ value: "Turkish", label: "Turkish" },
		{ value: "Ukrainian", label: "Ukrainian" },
	];

	// Check if the default language is already in the options
	let defaultOptionIndex = options.findIndex(
		(option) => option.value === mainLanguage
	);

	if (defaultOptionIndex !== -1) {
		// If it is, modify its label to include '(default)'
		options[
			defaultOptionIndex
		].label = `${options[defaultOptionIndex].label} (default)`;
		// Move the default option to the top of the array
		options.unshift(options.splice(defaultOptionIndex, 1)[0]);
	} else {
		// If it's not, add it to the options at the top
		options.unshift({
			value: mainLanguage,
			label: `${mainLanguage} (default)`,
		});
	}
	const [reset, setReset] = useState(false);
	const [userInput, setUserInput] = useState({
		installer_fallback: true,
		gpt: true,
		company: variables?.slug,
		demo_name: variables?.slug,
		device: "",
		problem: "",
		language: mainLanguage,
		instance_type: "installer",
	});

	const [feedbackAdded, setFeedbackAdded] = useState(false);
	const [showThumbs, setShowThumbs] = useState(true);

	const [data, setData] = useState({});
	const [loading, setLoading] = useState(false);
	const [fileLoading, setFileLoading] = useState(false);
	const [fileError, setFileError] = useState(null);
	const [error, setError] = useState(null);
	const [titleClicked, setTitleClicked] = useState(false);
	const [progress, setProgress] = useState(0);
	const [status, setStatus] = useState(uiText?.status?.processing);
	const [delay, setDelay] = useState(false);
	const [showProgressBar, setShowProgressBar] = useState(false);
	const [showData, setShowData] = useState(false);
	const [showQuestion, setShowQuestion] = useState(false);

	const [showBackButton, setShowBackButton] = useState(false);

	const [recording, setRecording] = useState(false);
	const [mediaRecorder, setMediaRecorder] = useState(null);
	const [transcription, setTranscription] = useState("");
	const [isSpinnerVisible, setIsSpinnerVisible] = useState(false);
	const [backBtnHovered, setBackBtnHovered] = useState(false);
	const [showFeedbackInput, setShowFeedbackInput] = useState(false);
	const [feedbackInput, setFeedbackInput] = useState("");
	const [exampleQuestions, setExampleQuestions] = useState([]);
	const [deviceNameToDisplay, setDeviceNameToDisplay] = useState("");
	const [relatedDevices, setRelatedDevices] = useState([]);
	const [recordId, setRecordId] = useState(null); 


	const handleResetForm = () => {
		setShowData(false);
		setShowQuestion(false);
		setShowProgressBar(false);
		setError(null);
		setFileError(null);
		setData({});
		setFeedbackAdded(false);
		setShowFeedbackInput(false);
		setShowThumbs(true);
		setShowBackButton(false);
		setBackBtnHovered(false);
		setUserInput((cur) => ({
			...cur,
			installer_fallback: true,
			gpt: true,
			company: variables?.slug,
			demo_name: variables?.slug,
			device: "",
			problem: "",
			instance_type: "installer",
		}));
		setRelatedDevices([]);
		setResetForm(false);
	};
	
	useEffect(() => {
		if (resetForm) {
			handleResetForm();
			setReset(true);
		}
	}, [resetForm]);

	useEffect(() => {
		setUserInput((prevUserInput) => ({
			...prevUserInput,
			language: mainLanguage,
		}));
	}, [mainLanguage]);

	useEffect(() => {
		if (userInput.device !== "" && variables.type === "Manufacturer") {
			setExampleQuestions(
				variables.devices.find(
					(device) => device.deviceName === userInput.device
				).exampleQuestions
			);
			setRelatedDevices(
				variables.devices.find(
					(device) => device.deviceName === userInput.device
				).relatedDevices || []
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userInput.device, variables.devices]);

	const fetchData = async () => {
		const element = document.getElementById("navbar");
		if (element) window.scrollTo(0, element.offsetTop);
		setShowQuestion(true);
		setTimeout(() => {
			setLoading(true);
		}, 10);
		try {
			// const environment = process.env.REACT_APP_ENV;
			let url = null;
			if (environment === "staging") {
				url = variables.newAi
					? "https://ai.service-assistant-staging.com/SMA_get_instructions"
					: "https://ai.service-assistant-staging.com/get_instructions";
			}
			if (environment === "production") {
				url = variables.newAi
					? "https://ai.service-assistant.ai/SMA_get_instructions"
					: "https://ai.service-assistant.ai/get_instructions";
			}

			if (url) {
				const res = await axios.post(
					url,
					{
						...userInput,
						...(!variables.newAi ? { instance_type: "installer" } : {}),
					},
					{
						headers: {
							"Content-Type": "application/json",
						},
					}
				);
				if (res.data) {
					setData(res.data);
					console.log(res.data);
					console.log(variables);
					// const element = document.getElementById("results");
					// if (element) window.scrollTo(0, element.offsetTop);
					setFeedbackAdded(false);
					setDeviceNameToDisplay(formatDeviceName(userInput.device));
					sendQuestionData(
						userInput.device,
						userInput.problem,
						res.data.gpt_response || ""
					).then((recordId) => {
						if (recordId) {
							setRecordId(recordId);
						}
					});
				} else {
					setData([]);
					setDeviceNameToDisplay("");
				}
			}
		} catch (err) {
			console.log(err.message); // Log only the error message
			setError(err.message); // Set only the error message
			if (environment === "production") {
				handleSendResult(false, data, userInput, variables.slug, false);
			}
			setShowProgressBar(false);
			setShowQuestion(false);
			setProgress(0);
			setStatus("");
			sendQuestionData(
				userInput.device,
				userInput.problem,
				err.message || "error"
			).then((recordId) => {
				if (recordId) {
					setRecordId(recordId);
				}
			});
		}
		setLoading(false);
		setTitleClicked(false);
		setFileError(null);
		setProgress(100); // Set progress to 100
		setStatus(uiText?.status?.complete); // Set status to "Complete"
		setTimeout(() => {
			setProgress(0);
			setStatus("");
			setShowProgressBar(false);
			setShowQuestion(false);
			setShowData(true);
		}, 300);
	};

	useEffect(() => {
		let interval = null;
		if (loading && !delay) {
			setShowProgressBar(true);
			if (progress === 0) {
				setStatus(uiText?.status?.processing);
			}
			interval = setInterval(() => {
				setProgress((prevProgress) => {
					let newProgress = prevProgress + 1;
					if (newProgress > 100) {
						newProgress = 100; // Ensure progress does not exceed 100
					}
					if (
						newProgress === 5 ||
						newProgress === 25 ||
						newProgress === 50 ||
						newProgress === 75
					) {
						setStatus(
							newProgress === 5
								? uiText?.status?.processing
								: newProgress === 25 || newProgress === 50
								? uiText?.status?.searching
								: uiText?.status?.generating
						);
						clearInterval(interval);
						setDelay(true);
						setTimeout(() => setDelay(false), 500); // Add a delay of half a second
					}
					return newProgress;
				});
			}, 200); // Adjust the time here to control the speed of the progress bar
		} else if (!loading && progress > 1) {
			setProgress(100);
			setStatus(uiText?.status?.complete);
			setTimeout(() => {
				setProgress(0);
				setStatus("");
				setShowProgressBar(false);
				setTimeout(() => setShowData(true), 300); // Delay showing data until after ProgressBar has disappeared
			}, 500);
		}
		return () => clearInterval(interval);
	}, [loading, delay, progress, uiText]);

	const openFile = async (fileName, page, range) => {
		setFileLoading(true);
		try {
			let url = null;
			if (process.env.REACT_APP_ENV === "production") {
				url = "https://ai.service-assistant.ai/file/download";
			} else {
				url = "https://ai.service-assistant-staging.com/file/download";
			}
			const response = await axios({
				method: "POST",
				url,
				data: {
					filename: `${fileName}`,
					company:
						variables.type === "Manufacturer"
							? variables.slug
							: userInput.company,
				},
				responseType: "arraybuffer",
			});
			if (response.data) {
				const blob = new Blob([response.data], { type: "application/pdf" });
				let url = window.URL.createObjectURL(blob);

				// Convert page and range to an array if they are not already
				// and convert elements to string if they are numbers
				const pageArray = Array.isArray(page)
					? page.map(String)
					: [String(page)];
				const rangeArray = Array.isArray(range)
					? range.map(String)
					: [String(range)];

				// Check if the range array is not empty
				if (rangeArray.length > 0 && rangeArray[0]) {
					url += "#page=" + rangeArray[0];
				}
				// If the range array is empty, check if the page array is not empty
				else if (pageArray.length > 0 && pageArray[0]) {
					url += "#page=" + pageArray[0];
				}

				if (window.confirm(uiText?.alerts?.openInNewTab)) {
					window.open(url, "_blank");
				}
			}
		} catch (err) {
			setFileError(err);
			console.log(err);
		}
		setFileLoading(false);
	};

	const handleSubmit = (e) => {
		e && e.preventDefault();
		if (!userInput) return;
		setData({});
		fetchData();
		// setUserInput({ device: "", problem: "", language: "English" });
		setError(null);
		setFileError(null);
		setShowBackButton(false);
	};

	useEffect(() => {
		if (Object.keys(data).length > 0 && environment === "production") {
			handleSendResult(true, data, userInput, variables.slug);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]); // This effect runs whenever `data` changes

	useEffect(() => {
		if (feedbackAdded) {
			setFeedbackIsAdded(true);
			setTimeout(() => {
				setShowThumbs(false);
				setFeedbackIsAdded(false);
			}, 3500);
		} else {
			setShowThumbs(true);
			setFeedbackIsAdded(false);
		}
	}, [feedbackAdded, setFeedbackIsAdded]);

	useEffect(() => {
		if (titleClicked) {
			handleSubmit();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [titleClicked]);

	// Cleanup function to stop recording and release media resources
	useEffect(() => {
		return () => {
			if (mediaRecorder) {
				mediaRecorder.stream.getTracks().forEach((track) => track.stop());
			}
		};
	}, [mediaRecorder]);

	if (!process.env.REACT_APP_GOOGLE_API_KEY) {
		throw new Error("REACT_APP_GOOGLE_API_KEY not found in the environment");
	}

	const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;

	const startRecording = async () => {
		try {
			const stream = await navigator.mediaDevices.getUserMedia({
				audio: true,
			});
			const recorder = new MediaRecorder(stream);
			recorder.start();
			console.log("Recording started");
			setUserInput({
				...userInput,
				problem: "",
			});

			// Event listener to handle data availability
			recorder.addEventListener("dataavailable", async (event) => {
				console.log("Data available event triggered");
				const audioBlob = event.data;

				const base64Audio = await audioBlobToBase64(audioBlob);
				//console.log('Base64 audio:', base64Audio);
				setIsSpinnerVisible(true);
				try {
					const startTime = performance.now();

					const response = await axios.post(
						`https://speech.googleapis.com/v1/speech:recognize?key=${apiKey}`,
						{
							config: {
								encoding: "WEBM_OPUS",
								sampleRateHertz: 48000,
								languageCode: languageCodeMap[userInput.language] || "en-US",
								enableAutomaticPunctuation: true,
								useEnhanced: true,
								model: "latest_long",
							},
							audio: {
								content: base64Audio,
							},
						}
					);

					const endTime = performance.now();
					const elapsedTime = endTime - startTime;

					//console.log('API response:', response);
					console.log("Time taken (ms):", elapsedTime);

					if (response.data.results && response.data.results.length > 0) {
						setIsSpinnerVisible(false);
						setTranscription(
							response.data.results[0].alternatives[0].transcript
						);
						setUserInput({
							...userInput,
							problem: response.data.results[0].alternatives[0].transcript,
						});
					} else {
						console.log(
							"No transcription results in the API response:",
							response.data
						);
						setTranscription(uiText?.form?.noTranscription);
					}
					setIsSpinnerVisible(false);
				} catch (error) {
					console.error(
						"Error with Google Speech-to-Text API:",
						error.response.data
					);
				}
			});
			setRecording(true);
			setMediaRecorder(recorder);
		} catch (error) {
			console.error("Error getting user media:", error);
		}
	};

	const stopRecording = () => {
		if (mediaRecorder) {
			mediaRecorder.stop();
			console.log("Recording stopped");
			setRecording(false);
			// Stop the MediaStream
			mediaRecorder.stream.getTracks().forEach((track) => track.stop());
		}
	};

	let uniqueFiles = [...new Set(data?.similar_files)];

	// Filter articles with non-empty titles and descriptions
	const filteredArticles = data?.articles?.filter(
		(article) => article.Title && article.Description
	);

	const hideForm =
		showProgressBar ||
		error ||
		fileError ||
		(showData && Object.keys(data).length > 0) ||
		loading ||
		showQuestion;

	useEffect(() => {
		if (
			!loading &&
			((showData && Object.keys(data).length > 0) || error || fileError)
		) {
			setTimeout(() => {
				setShowBackButton(true);
			}, 1000);
		}
	}, [showData, data, error, fileError, loading]);

	const handleCheckboxhange = (event) => {
		setUserInput({
			...userInput,
			installer_fallback: event.target.checked,
		});
	};

	return (
		<>
			<div className={fileLoading ? "modal visible" : "modal"}>
				<div className="modal-content">
					<span>{uiText?.form?.loadingFile}</span>
				</div>
			</div>
			<div className="form-page">
				<div className="form-container">
					<div className="ai-avatar__container">
						<div className="ai-avatar">
							<div className="ai-avatar__name">{variables.name}</div>
							<span style={{ paddingLeft: "0px" }}>Service Assistant</span>
						</div>
					</div>
					<div
						className={
							hideForm
								? "ai-avatar__container--mobile hide"
								: "ai-avatar__container--mobile"
						}
					>
						<div className="ai-avatar__mobile">
							<div>{variables.name}</div>
							<div className="ai-avatar">
								<span style={{ paddingLeft: "0px", color: "black" }}>
									Service Assistant
								</span>
							</div>
						</div>
					</div>
					<form
						onSubmit={handleSubmit}
						className={hideForm ? "form hide" : "form"}
					>
						<div className="form-group">
							{variables.type === "Manufacturer" ? (
								<>
									{" "}
									<label htmlFor="device">
										{`${uiText?.form?.deviceModel}`}
									</label>
									<AutoSelect
										items={variables.devices}
										setSelectedDevice={(device) =>
											setUserInput({ ...userInput, device })
										}
										device={userInput.device}
									/>
								</>
							) : variables.type === "Distributor" ? (
								<AutoSelectDistributors
									items={variables.deviceTypes}
									userInput={userInput}
									setUserInput={setUserInput}
									setExampleQuestions={setExampleQuestions}
									setRelatedDevices={setRelatedDevices}
									reset={reset}
									setReset={setReset}
								/>
							) : (
								<AutoSelectManufacturerAdvanced
									items={variables.categoriesContainer}
									userInput={userInput}
									setUserInput={setUserInput}
									setExampleQuestions={setExampleQuestions}
									setRelatedDevices={setRelatedDevices}
									reset={reset}
									setReset={setReset}
								/>
							)}

							<div className="divider"></div>
							<label htmlFor="problem">{uiText?.form?.question}</label>
							<div className="input-container">
								<textarea
									type="text"
									id="problem"
									name="problem"
									className="card"
									placeholder={
										recording || isSpinnerVisible
											? ""
											: transcription === uiText?.form?.noTranscript
											? uiText?.form?.noTranscriptMessage
											: uiText?.form?.questionPlaceholder
									}
									value={userInput.problem || ""}
									onChange={(e) =>
										setUserInput({ ...userInput, problem: e.target.value })
									}
								/>
								{isSpinnerVisible && (
									<div
										style={{
											position: "absolute",
											marginInline: "auto",
											left: "42%",
										}}
									>
										<div className="spnr"></div>
									</div>
								)}
								{!recording && (
									<div
										className={
											isSpinnerVisible ? "microphone disabled" : "microphone"
										}
										onClick={isSpinnerVisible ? null : startRecording}
										style={{ backgroundColor: variables.mainColor }}
									>
										<MicrophoneIcon
											color={
												getBrightness(variables.mainColor) > 155
													? "#000000"
													: "#ffffff"
											}
										/>
									</div>
								)}

								{recording && (
									<div style={{ position: "relative", marginInline: "auto" }}>
										<VoiceAnimation />
										<div className="stop-recording__container">
											<div
												className="stop-recording__background"
												style={{ backgroundColor: variables.mainColor }}
											></div>
											<div
												className="stop-recording"
												onClick={stopRecording}
												style={{ backgroundColor: variables.mainColor }}
											>
												<span
													style={{
														borderColor:
															getBrightness(variables.mainColor) > 155
																? "#000000"
																: "#ffffff",
													}}
												></span>
											</div>
										</div>
									</div>
								)}
							</div>
							{exampleQuestions.length ? (
								<>
									<label htmlFor="problem" style={{ marginTop: "2px" }}>
										{uiText?.form?.exampleQuestions}:
									</label>
									<div
										className="example-questions first"
										style={{ marginBottom: "8px" }}
									>
										<div
											key={0}
											className="example-question"
											onClick={() =>
												isSpinnerVisible || recording || loading
													? null
													: setUserInput({
															...userInput,
															problem: exampleQuestions[0],
													  })
											}
											style={{
												borderColor: variables.mainColor,
												cursor:
													isSpinnerVisible || recording
														? "not-allowed"
														: "pointer",
											}}
										>
											{exampleQuestions[0]}
										</div>
									</div>
									<div className="example-questions">
										{exampleQuestions.slice(1).map((question, index) => (
											<div
												key={index + 1}
												className="example-question"
												onClick={() =>
													isSpinnerVisible || recording || loading
														? null
														: setUserInput({
																...userInput,
																problem: question,
														  })
												}
												style={{
													borderColor: variables.mainColor,
													cursor:
														isSpinnerVisible || recording
															? "not-allowed"
															: "pointer",
												}}
											>
												{question}
											</div>
										))}
									</div>
								</>
							) : null}

							<label
								htmlFor="language"
								style={{
									marginTop:
										exampleQuestions.length || recording ? "16px" : "32px",
								}}
							>
								{uiText?.form?.language}
							</label>
							<Select
								options={options}
								values={options.filter(
									(option) => option.value === userInput.language
								)}
								searchable="false"
								className="select card"
								onChange={(selectedOption) => {
									setUserInput({
										...userInput,
										language: selectedOption[0].value,
									});
									changeInstanceLanguage(selectedOption[0].value);
								}}
							/>
							<button
								type="submit"
								className={
									userInput.device === "" ||
									userInput.problem === "" ||
									!variables?.aiTrained
										? "submit-btn disabled"
										: "submit-btn"
								}
								disabled={
									userInput.device === "" ||
									userInput.problem === "" ||
									!variables?.aiTrained
								}
								style={{
									backgroundColor: variables.mainColor,
									color:
										getBrightness(variables.mainColor) > 155
											? "black"
											: "white",
									border: `1px solid ${variables.mainColor}`,
								}}
							>
								{uiText?.form?.submit}
							</button>
							<label
								className="options-checkbox mobile"
								style={{
									color:
										userInput.installer_fallback === true
											? variables.mainColor
											: "#90A0B7",
								}}
							>
								{uiText?.result?.protectionSystem}
								<input
									name="origin"
									type="checkbox"
									value={userInput.installer_fallback}
									checked={userInput.installer_fallback === true}
									onChange={handleCheckboxhange}
								/>
								<span
									className="checkmark"
									style={{
										borderColor:
											userInput.installer_fallback === true
												? variables.mainColor
												: "#42536D",
										backgroundColor:
											userInput.installer_fallback === true
												? variables.mainColor
												: "transparent",
									}}
								></span>
							</label>
						</div>
					</form>
				</div>
				{showQuestion && <div className="question">{userInput.problem}</div>}
				<div
					className={
						showProgressBar ||
						error ||
						fileError ||
						(showData && Object.keys(data).length > 0)
							? "feedback-container show"
							: "feedback-container"
					}
					id="results"
				>
					<div className="ai-avatar results">
						<ResultsIcon
							circleColor={variables.mainColor}
							color={
								getBrightness(variables.mainColor) > 155 ? "black" : "white"
							}
						/>
						<span>{uiText?.result?.title}</span>
						<label
							className="options-checkbox desktop"
							style={{
								color:
									userInput.installer_fallback === true
										? variables.mainColor
										: "#90A0B7",
							}}
						>
							{uiText?.result?.protectionSystem}
							<input
								name="origin"
								type="checkbox"
								value={userInput.installer_fallback}
								checked={userInput.installer_fallback === true}
								onChange={handleCheckboxhange}
							/>
							<span
								className="checkmark"
								style={{
									borderColor:
										userInput.installer_fallback === true
											? variables.mainColor
											: "#42536D",
									backgroundColor:
										userInput.installer_fallback === true
											? variables.mainColor
											: "transparent",
								}}
							></span>
						</label>
					</div>
					<div
						className={
							!Object.keys(data).length > 0 || showProgressBar
								? "feedback card"
								: "feedback card hide"
						}
					>
						{error && <NoResults txt={variables.customFallbackMessage ?? ""} />}
						{fileError && (
							<Alert
								message="Unable to open the file"
								duration={5000} // Duration in milliseconds
								close={() => setFileError(null)}
							/>
						)}
						{showProgressBar && (
							<div className="progress-desktop">
								<ProgressBar
									progress={progress}
									status={status}
									color={variables.mainColor}
								/>
							</div>
						)}
						{showData && Object.keys(data).length > 0 ? (
							<>
								{data?.exact_file && !data?.empty_answer
									? (() => {
											let fileName;
											if (Array.isArray(data.exact_file)) {
												const fileObj = data.exact_file[0]; // Get the first object from the array
												fileName = Object.keys(fileObj)[0]; // Get the key of the object which is the file name
											} else {
												fileName = data.exact_file;
											}

											const filenameWithoutExtension = fileName.slice(
												0,
												fileName.lastIndexOf(".")
											);
											return (
												<div
													className={
														variables.newAi
															? "feedback-top feedback-top__with-tabs"
															: "feedback-top installer"
													}
												>
													<div className="document-title" style={{ flex: "1" }}>
														{userInput.problem.length > 0
															? userInput.problem
															: deviceNameToDisplay !== ""
															? deviceNameToDisplay
															: filenameWithoutExtension}
													</div>
												</div>
											);
									  })()
									: null}
								{variables.newAi ? (
									<Tabs className="Tabs">
										<TabList>
											<Tab>{uiText?.result?.tabs.instructions.title}</Tab>
											<Tab>{uiText?.result?.tabs.relatedArticles.title}</Tab>
										</TabList>
										<TabPanel>
											<div style={{ padding: "1rem 0px 0px 12px" }}>
												{data?.articles_gpt_response === true &&
												filteredArticles &&
												filteredArticles.length > 0 ? (
													<div>
														<div className="feedback-header">
															<span>
																{
																	uiText?.result?.tabs.instructions
																		.articlesTitle
																}
															</span>
														</div>
														<Accordion data={[filteredArticles[0]]} single />
													</div>
												) : null}
												<div
													className={
														data?.exact_file
															? Array.isArray(data.exact_file)
																? !Object.keys(data.exact_file[0])[0].endsWith(
																		".pdf"
																  )
																	? "feedback-documents none"
																	: "feedback-documents"
																: !data.exact_file.endsWith(".pdf")
																? "feedback-documents none"
																: "feedback-documents"
															: "feedback-documents none"
													}
													style={{ paddingLeft: "0px" }}
												>
													{data?.exact_file && !data?.empty_answer
														? (() => {
																let fileName;
																if (Array.isArray(data.exact_file)) {
																	const fileObj = data.exact_file[0]; // Get the first object from the array
																	fileName = Object.keys(fileObj)[0]; // Get the key of the object which is the file name
																} else {
																	fileName = data.exact_file;
																}

																// Convert page to an array if it's not already
																const pageArray = Array.isArray(data.page)
																	? data.page
																	: [data.page];
																const pageText = pageArray.join(", ");
																const isMultiplePages = pageArray.length > 1;

																return (
																	<div className="feedback-documents__col">
																		<div className="feedback-header">
																			<span>
																				{
																					uiText?.result?.tabs.instructions
																						.technicalDocumentsTitle
																				}
																				:
																			</span>
																		</div>

																		<div className="feedback-file">
																			<span
																				className="file-title"
																				onClick={() =>
																					openFile(
																						fileName,
																						data.page || [],
																						data.range || []
																					)
																				}
																				style={{ color: variables.mainColor }}
																			>
																				<DownloadIcon
																					color={variables.mainColor}
																				/>
																				{fileName+ ", "}
																			</span>
																			{pageText && (
																				<span
																					style={{ color: variables.mainColor }}
																				>
																					{isMultiplePages ? "pages" : "page"}{" "}
																					{pageText}
																				</span>
																			)}
																		</div>
																	</div>
																);
														  })()
														: null}

													{uniqueFiles.length > 0 &&
													data?.exact_file &&
													!data?.empty_answer ? (
														<div className="feedback-documents__col">
															<div className="feedback-header">
																<span>
																	{
																		uiText?.result?.tabs?.instructions
																			?.relatedDevices
																	}
																</span>
															</div>
															{uniqueFiles.map((file, index) => {
																const fileName = file;
																const filenameWithoutExtension = fileName.slice(
																	0,
																	fileName.lastIndexOf(".")
																);
																return (
																	<div key={index} className="feedback-file">
																		<div className="feedback_icon">
																			<ArrowIcon color={variables.mainColor} />
																		</div>
																		<span
																			className="file-title"
																			onClick={() => {
																				// Update userInput with the new title
																				setUserInput({
																					...userInput,
																					device: filenameWithoutExtension,
																				});
																				setTitleClicked(true);
																				environment === "production" &&
																					handleSendRelatedDeviceClick(
																						fileName,
																						data,
																						userInput,
																						variables.slug,
																						false
																					);
																			}}
																			style={{ color: variables.mainColor }}
																		>
																			{filenameWithoutExtension}
																		</span>
																	</div>
																);
															})}
														</div>
													) : null}
												</div>
												<div className="feedback-instructions">
													{data?.gpt_response && !data?.empty_answer ? (
														<>
															<div className="feedback-documents__col">
																{typeof data.warning === "string" &&
																data.warning.length > 0 ? (
																	<Warning warningText={data.warning} />
																) : null}
																<div
																	className="feedback-header"
																	style={{ marginTop: "1rem" }}
																>
																	<span>
																		{uiText?.result?.tabs.instructions.title}:
																	</span>
																</div>
																<div className="feedback-instruction">
																	<StepsComponent
																		inputText={data.gpt_response}
																		language={userInput.language}
																	/>
																</div>
															</div>
														</>
													) : data?.gpt_response && data.empty_answer ? (
														<NoResults
															txt={variables.customFallbackMessage ?? ""}
														/>
													) : null}
												</div>
											</div>
										</TabPanel>
										<TabPanel>
											{filteredArticles && filteredArticles.length > 1 ? (
												<Accordion data={filteredArticles.slice(1)} />
											) : (
												<div className="no-article">
													<p>
														{filteredArticles && filteredArticles.length === 1
															? uiText?.result?.tabs.relatedArticles
																	.noRelatedArticlesMessage1
															: uiText?.result?.tabs.relatedArticles
																	.noRelatedArticlesMessage2}
													</p>
												</div>
											)}
										</TabPanel>
									</Tabs>
								) : (
									<>
										<div
											className={
												data?.exact_file
													? "feedback-documents installer"
													: "feedback-documents none"
											}
										>
											{data?.exact_file && !data.empty_answer
												? (() => {
														let fileName;
														if (Array.isArray(data.exact_file)) {
															const fileObj = data.exact_file[0]; // Get the first object from the array
															fileName = Object.keys(fileObj)[0]; // Get the key of the object which is the file name
														} else {
															fileName = data.exact_file;
														}

														// Convert page to an array if it's not already
														const pageArray = Array.isArray(data.page)
															? data.page
															: [data.page];
														const pageText = pageArray.join(", ");
														const isMultiplePages = pageArray.length > 1;

														return (
															<div className="feedback-documents__col all-borders">
																<div className="feedback-header">
																	<span className="fixed-width">
																		{`${uiText?.form?.deviceModel}`}:
																	</span>
																	<span style={{ fontWeight: 400 }}>
																		{formatDeviceName(userInput.device)}
																	</span>
																</div>
																{relatedDevices.length > 0 &&
																!data.empty_answer ? (
																	<div className="feedback-header">
																		<span className="fixed-width">
																			{
																				uiText?.result?.tabs?.instructions
																					?.relatedDevices
																			}
																			:
																		</span>
																		{relatedDevices.map((device, index) => (
																			<div
																				key={index}
																				className="related-device"
																				style={{ color: variables.mainColor, cursor: 'pointer' }}
																				onClick={() => {
																					setUserInput({
																						...userInput,
																						device,
																					});
																				}}
																			>
																				{`${formatDeviceName(device)}${
																					index === relatedDevices.length - 1
																						? ""
																						: ","
																				}`}
																			</div>
																		))}
																	</div>
																) : null}
																<div
																	className="feedback-header"
																	style={{ marginBottom: "0" }}
																>
																	<span className="fixed-width">
																		{uiText?.result?.sources}:
																	</span>
																	<div className="feedback-file">
																		<span
																			className="file-title"
																			onClick={() =>
																				openFile(
																					fileName,
																					data.page || [],
																					data.range || []
																				)
																			}
																			style={{
																				color: variables.mainColor,
																				flexWrap: "wrap",
																			}}
																		>
																			<div
																				className="feedback-type"
																				style={{
																					background: variables.mainColor,
																					color:
																						getBrightness(variables.mainColor) >
																						155
																							? "#000000"
																							: "#ffffff",
																				}}
																			>
																				PDF
																			</div>
																			{fileName + ", "}
																			{pageText && (
																				<span
																					style={{
																						color: "#6982A8",
																						marginLeft: "3px",
																					}}
																				>
																					{isMultiplePages
																						? " " + uiText?.result?.pages
																						: " " + uiText?.result?.page}
																					{" " + pageText}
																				</span>
																			)}
																		</span>
																	</div>
																</div>
															</div>
														);
												  })()
												: null}

											{uniqueFiles.length > 0 &&
											data?.exact_file &&
											!data.empty_answer ? (
												<div className="feedback-documents__col">
													<div className="feedback-header">
														<span>
															{
																uiText?.result?.tabs?.instructions
																	?.relatedDevices
															}
														</span>
													</div>
													{uniqueFiles.map((file, index) => {
														const fileName = file;
														const filenameWithoutExtension = fileName.slice(
															0,
															fileName.lastIndexOf(".")
														);
														return (
															<div key={index} className="feedback-file">
																<div className="feedback_icon">
																	<ArrowIcon color={variables.mainColor} />
																</div>
																<span
																	className="file-title"
																	onClick={() => {
																		// Update userInput with the new title
																		setUserInput({
																			...userInput,
																			device: filenameWithoutExtension,
																		});
																		setTitleClicked(true);
																		environment === "production" &&
																			handleSendRelatedDeviceClick(
																				fileName,
																				data,
																				userInput,
																				variables.slug,
																				false
																			);
																	}}
																	style={{ color: variables.mainColor }}
																>
																	{filenameWithoutExtension}
																</span>
															</div>
														);
													})}
												</div>
											) : null}
										</div>
										<div className="feedback-instructions installer">
											{data?.gpt_response && !data.empty_answer ? (
												<>
													<div className="feedback-documents__col">
														{typeof data.warning === "string" &&
														data.warning.length > 0 ? (
															<Warning warningText={data.warning} />
														) : null}
														<div
															className="feedback-header"
															style={{ marginTop: "1rem" }}
														>
															<span>{uiText?.result?.instructions}:</span>
														</div>
														<div className="feedback-instruction">
															<StepsComponent
																inputText={data.gpt_response}
																language={userInput.language}
															/>
														</div>
													</div>
												</>
											) : data?.gpt_response && data.empty_answer ? (
												<NoResults
													txt={variables.customFallbackMessage ?? ""}
												/>
											) : null}
										</div>
									</>
								)}

								{showThumbs && data?.exact_file ? (
									<div
										className={
											showFeedbackInput
												? "user-feedback show-input"
												: "user-feedback"
										}
									>
										{feedbackAdded ? (
											<div className="user-feedback__text">
												{uiText?.result?.feedback.thankYou}
											</div>
										) : showFeedbackInput ? (
											<div className="user-feedback__input">
												<div
													className="close-button__feedback "
													onClick={() => setShowFeedbackInput(false)}
												>
													<CloseIcon />
												</div>
												<div className="feedback-text">
													{uiText?.result?.feedback.Description}
												</div>
												<textarea
													value={feedbackInput}
													onChange={(e) => setFeedbackInput(e.target.value)}
													placeholder={
														uiText?.result?.feedback.inputPlaceholder
													}
													type="text"
													id="feedback-input"
													name="feedback-input"
													className="card"
												/>
												<button
													type="submit"
													className="submit-btn"
													style={{
														backgroundColor: variables.mainColor,
														color:
															getBrightness(variables.mainColor) > 155
																? "black"
																: "white",
														border: `1px solid ${variables.mainColor}`,
														marginTop: "12px",
													}}
													onClick={() => {
														if (environment === "production") {
															handleFeedbackSubmit(
																"Thumb Down",
																data,
																userInput,
																setFeedbackAdded,
																variables.slug,
																feedbackInput,
																setShowFeedbackInput,
																false
															);
														} else {
															setFeedbackAdded(true);
															setShowFeedbackInput(false);
														}
														sendUserFeedback(recordId, false);
													}}
												>
													<div className="thumb-button">
														<ThumbDownIcon />
														{uiText.form.submit}
													</div>
												</button>
											</div>
										) : (
											<>
												<div className="user-feedback__text">
													{uiText?.result?.feedback.title}
												</div>
												<div className="user-feedback__buttons">
													<div
														className="user-feedback__button"
														onClick={() => setShowFeedbackInput(true)}
													>
														<ThumbDownIcon />
													</div>
													<div
														className="user-feedback__button"
														onClick={() => {
															if (environment === "production") {
																handleFeedbackSubmit(
																	"Thumb Up",
																	data,
																	userInput,
																	setFeedbackAdded,
																	variables.slug,
																	false
																);
															} else {
																setFeedbackAdded(true);
															}
															sendUserFeedback(recordId, true);
														}}
													>
														<ThumbUpIcon />
													</div>
												</div>
											</>
										)}
									</div>
								) : null}
								{Object.keys(data).length > 0 &&
									data?.exact_file === null &&
									!data.empty_answer(
										<NoResults txt={variables.customFallbackMessage ?? ""} />
									)}
							</>
						) : null}
					</div>
				</div>
				{showProgressBar && (
					<div className="progress-mobile">
						<ProgressBar
							progress={progress}
							status={status}
							color={variables.mainColor}
						/>
					</div>
				)}
				<div className="error-mobile">
					{error && <NoResults txt={variables.customFallbackMessage ?? ""} />}
					{fileError && (
						<Alert
							message={uiText?.alerts?.fileOpenError}
							duration={5000} // Duration in milliseconds
							close={() => setFileError(null)}
						/>
					)}
				</div>
				{showBackButton && showThumbs && data?.exact_file ? (
					<div
						className={
							showFeedbackInput
								? "user-feedback__mobile show-input"
								: "user-feedback__mobile"
						}
					>
						{feedbackAdded ? (
							<div className="user-feedback__text">
								{uiText?.result?.feedback.thankYou}
							</div>
						) : showFeedbackInput ? (
							<div className="user-feedback__input">
								<div
									className="close-button__feedback "
									onClick={() => setShowFeedbackInput(false)}
								>
									<CloseIcon />
								</div>
								<div className="feedback-text">
									{uiText?.result?.feedback.Description}
								</div>
								<textarea
									value={feedbackInput}
									onChange={(e) => setFeedbackInput(e.target.value)}
									placeholder="Why was this response not helpful?"
									type="text"
									id="feedback-input"
									name="feedback-input"
									className="card"
								/>
								<button
									type="submit"
									className="submit-btn"
									style={{
										backgroundColor: variables.mainColor,
										color:
											getBrightness(variables.mainColor) > 155
												? "black"
												: "white",
										border: `1px solid ${variables.mainColor}`,
										marginTop: "12px",
									}}
									onClick={() => {
										if (environment === "production") {
											handleFeedbackSubmit(
												"Thumb Down",
												data,
												userInput,
												setFeedbackAdded,
												variables.slug,
												feedbackInput,
												setShowFeedbackInput,
												false
											);
										} else {
											setFeedbackAdded(true);
											setShowFeedbackInput(false);
										}
										sendUserFeedback(recordId, false);
									}}
								>
									<div className="thumb-button">
										<ThumbDownIcon />
										{uiText?.result?.feedback.submit}
									</div>
								</button>
							</div>
						) : (
							<>
								<div className="user-feedback__text">
									{uiText?.result?.feedback.title}
								</div>
								<div className="user-feedback__buttons">
									<div
										className="user-feedback__button"
										onClick={() => setShowFeedbackInput(true)}
									>
										<ThumbDownIcon />
									</div>
									<div
										className="user-feedback__button"
										onClick={() => {
											if (environment === "production") {
												handleFeedbackSubmit(
													"Thumb Up",
													data,
													userInput,
													setFeedbackAdded,
													variables.slug,
													false
												);
											} else {
												setFeedbackAdded(true);
											}
											sendUserFeedback(recordId, true);
										}}
									>
										<ThumbUpIcon />
									</div>
								</div>
							</>
						)}
					</div>
				) : null}
				{showBackButton && (
					<div className="back-btn">
						<button
							onClick={() => {
								setShowData(false);
								setShowQuestion(false);
								setShowProgressBar(false);
								setError(null);
								setFileError(null);
								setData({});
								setFeedbackAdded(false);
								setShowFeedbackInput(false);
								setShowThumbs(true);
								setShowBackButton(false);
								setBackBtnHovered(false);
							}}
							onMouseEnter={() => setBackBtnHovered(true)}
							onMouseLeave={() => setBackBtnHovered(false)}
							style={{
								borderColor: variables.mainColor,
								backgroundColor: backBtnHovered
									? variables.mainColor
									: "transparent",
							}}
						>
							<span>{uiText?.askNewQuestion}</span>
							<ArrowIcon color={"#000000"} />
						</button>
					</div>
				)}
			</div>
		</>
	);
};
export default FormContainer;
