/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";
import Select from "react-select";
import { formatDeviceName, manufacturerLogo } from "../utils";
import useUIText from "../hooks/translation";

const AutoSelectDistributorsCustomer = ({
  items,
  userInput,
  setUserInput,
  setExampleQuestions,
  showAsterisk = false,
  reset,
  setReset,
  handleSetShowFaqs,
  setRelatedDevices,
}) => {
  const uiText = useUIText();
  const [manufacturers, setManufacturers] = useState([]);
  const [devices, setDevices] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedManufacturer, setSelectedManufacturer] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState(null);

  const types = useMemo(() => {
    return items
      .map((item) => item.type)
      .map((item) => ({
        value: item,
        label: uiText?.deviceTypes?.[item?.toLowerCase()] || item,
      }));
  }, [items, uiText]);

  useEffect(() => {
    if (selectedType) {
      const manufacturers = items
        .find((item) => item.type === selectedType.value)
        .manufacturers.map((item) => item.manufacturer)
        .map((item) => ({ value: item, label: item }));
      setManufacturers(manufacturers);
      setSelectedManufacturer(null); // Reset selected manufacturer when type changes
      setDevices([]); // Reset devices when type changes
      setSelectedDevice(null); // Reset selected device when type changes
      setUserInput({ ...userInput, device: "" });
    } else {
      setManufacturers([]);
      setSelectedManufacturer(null);
      setDevices([]);
      setSelectedDevice(null);
      setExampleQuestions([]);
    }
  }, [items, selectedType]);

  useEffect(() => {
    if (selectedType && selectedManufacturer) {
      const manufacturer = items
        .find((item) => item.type === selectedType.value)
        .manufacturers.find(
          (item) => item.manufacturer === selectedManufacturer.value
        );
      if (manufacturer) {
        const devices = manufacturer.devices.map((item) => ({
          value: item.deviceName,
          label: formatDeviceName(item.deviceName),
        }));
        setDevices(devices);
        setSelectedDevice(null); // Reset selected device when manufacturer changes
        setUserInput({
          ...userInput,
          device: "",
          company: manufacturer.companyName,
        });
        setExampleQuestions([]);
      } else {
        setDevices([]);
        setSelectedDevice(null);
        setUserInput({ ...userInput, device: "" });
        setExampleQuestions([]);
      }
    } else {
      setDevices([]);
      setSelectedDevice(null);
      setExampleQuestions([]);
    }
  }, [items, selectedManufacturer, selectedType]);

  useEffect(() => {
    if (reset) {
      setSelectedType(null);
      setSelectedManufacturer(null);
      setSelectedDevice(null);
      setExampleQuestions([]);
      setReset(false);
    }
  }, [reset]);
  return (
		<div className="selection-wrapper">
			<div className="selection-title">
				{!selectedDevice ? uiText.form.selectDevice : uiText.form.yourDevice}
			</div>
			<div className="selection-container" style={{ paddingTop: "4px" }}>
				<div className="customer-selection">
					<Select
						className="basic-single"
						classNamePrefix="select"
						isClearable
						isSearchable
						options={types}
						placeholder={uiText?.form?.deviceTypePlaceholder}
						value={selectedType}
						onChange={(value) => {
							setSelectedType(value);
							setUserInput({ ...userInput, device: "", problem: "" });
						}}
						menuPlacement="top"
					/>
					<Select
						className="basic-single"
						classNamePrefix="select"
						isClearable
						isSearchable
						options={manufacturers}
						placeholder={
							manufacturers.length === 0
								? uiText?.form?.manufacturerPlaceholder1
								: uiText?.form?.manufacturerPlaceholder2
						}
						value={selectedManufacturer}
						onChange={(value) => {
							setSelectedManufacturer(value);
							setUserInput({ ...userInput, device: "", problem: "" });
						}}
						isDisabled={manufacturers.length === 0}
						menuPlacement="top"
						formatOptionLabel={({ value, label }) => (
							<div style={{ display: "flex", alignItems: "center" }}>
								<div style={{ width: "60px", textAlign: "center" }}>
									{manufacturerLogo(value) ? (
										<img
											src={manufacturerLogo(value)}
											alt={label}
											style={{
												maxWidth: "50px",
												height: "auto",
												maxHeight: "22px",
												marginRight: 10,
											}}
										/>
									) : null}
								</div>
								{label}
							</div>
						)}
					/>
					<Select
						className="basic-single"
						classNamePrefix="select"
						isClearable
						isSearchable
						options={devices}
						placeholder={
							devices.length === 0
								? uiText?.form?.deviceModelPlaceholder1
								: uiText?.form?.deviceModelPlaceholder2
						}
						value={selectedDevice}
						onChange={(value) => {
							const questions = items
								.find((item) => item.type === selectedType.value)
								.manufacturers.find(
									(item) => item.manufacturer === selectedManufacturer.value
								)
								.devices.find(
									(item) => item.deviceName === value.value
								).exampleQuestions;
							const relatedDevices = items
								.find((item) => item.type === selectedType.value)
								.manufacturers.find(
									(item) => item.manufacturer === selectedManufacturer.value
								)
								.devices.find(
									(item) => item.deviceName === value.value
								).relatedDevices;

							setSelectedDevice(value);
							setUserInput({
								...userInput,
								device: value?.value || "",
								problem: "",
							});
							setExampleQuestions(questions);
							setRelatedDevices(relatedDevices || []);
							if (questions.length > 0) {
								handleSetShowFaqs(false);
							}
						}}
						isDisabled={devices.length === 0}
						menuPlacement="top"
					/>
				</div>
			</div>
		</div>
	);
};

export default AutoSelectDistributorsCustomer;
